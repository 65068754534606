
.footer-info{
  background: black;
}

.contact-wrapper{
  background: #ffffff;
}

.banner1-wrapper .img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.banner1-wrapper .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner1-wrapper .content-wrapper {
  min-height: 520px;
  position: relative;
  z-index: "2";
  display: flex;
  align-items: center;
  background: linear-gradient(45deg, #000000d4, transparent);
}

.product-container-wrapper .wrapper{
  height: 100%;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.356),inset 10px 10px 15px rgba(0, 0, 0, 0.144);
  
}
.product-container-wrapper .wrapper a{
  color: #0b719f;
  font-weight: 600;
  margin-bottom: 10px;
  display: inline-block;
}











.contact-page-list {
  padding: 20px 0;
}

.contact-page-list li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 25px 0;
}

.contact-page-list li img {
  width: 40px;
  margin-right: 20px;
}
.page-heading {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  background: linear-gradient(45deg, #00000096, transparent);
}
.page-heading h1 {
  color: white;
}
.page-banner .banner-content {
  background: #00000070;
}

.px-50 {
  padding-left: 50px;
  padding-right: 50px;
}
.content-wrapper p {
  font-size: 16px;
}

.content-main-wrapper {
  border-radius: 32px;
  overflow: hidden;
  background: #ff4350;
  border: none;
  color: white;
}

.how-works {
  border-radius: 32px;
  overflow: hidden;
  position: relative;
}



.hide-content-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: none;
  align-items: center;
}
.hide-content-wrapper.show {
  display: flex;
}

.hide-content-wrapper .hide-text {
  height: 70vh;
  background: white;
  border-radius: 32px;
  color: black;
  padding: 50px;
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
}

.cancle-btn {
  position: absolute;
  top: 20px;
  right: 20px;
}

.cancle-btn svg {
  font-size: 25px;
}

.about-banner {
  padding-top: 20px;
  padding-bottom: 50px;
}

.about-banner h1 {
  font-size: 45px;
  font-weight: 700;
}
.about-banner p {
  font-size: 20px;
}

.about-img {
  border-radius: 150px;
  overflow: hidden;
}

.snapshot-wrapper.cources-section .content-wrapper .text{
padding-bottom: 32px;
}

.snapshot-wrapper .box-wrapper {
  border-radius: 50px 50px 20px 20px;
  background: linear-gradient(222deg, rgb(255 67 80), rgb(248, 73, 85));
  color: white;
  min-height: 600px;
}

.snapshot-wrapper .img-wrapper {
  border-radius: 50px;
  overflow: hidden;
}

.snapshot-wrapper .text {
  padding: 30px 15px 15px 15px;
}
.snapshot-wrapper .text h2 {
  font-size: 25px;
}

.box-wrapper2 {
  margin-top: 40px;
}
.box-wrapper3 {
  margin-top: 80px;
}
.box-wrapper4 {
  margin-top: 120px;
}

.ant-collapse-header .ant-collapse-expand-icon {
  position: absolute;
  right: 20px;
  top: 25px;
}
.ant-collapse-header .ant-collapse-expand-icon svg {
  font-size: 30px;
}
.ant-collapse-content-box p {
  font-size: 16px;
}

.ant-collapse-header-text {
  font-size: 30px;
  font-weight: 300;
}

.our-vesion h2 {
  position: relative;
  font-weight: 300;
  padding-left: 20px;
  margin-top: 20px;
}
.our-vesion h2:after {
  left: 0;
  top: 0;
  height: 100%;
  background: red;
  content: "";
  width: 4px;
  position: absolute;
  border-radius: 5px;
}
.our-vesion .ant-card,
.our-vesion .ant-collapse-header {
  background-color: #ffffff;
  box-shadow: none;
}

.input-group-text {
  border: 1px solid #ff4350;
}
.form-control {
  border: 1px solid #ff4350;
}

.form-input-wrapper textarea {
  border: 1px solid #ff4350 !important;
}

.video-wrapper .play {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000080;
}
.video-wrapper .play img {
  width: auto;
}
.video-wrapper video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video-wrapper {
  width: 60%;
}



.costum-container {
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
}
.blog-detail-wrapper{
    padding-left: 110px;
    padding-right: 110px;
}

.author .img {
    width: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
}



.blog-img {
  position: relative;
}
.blog-img::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, black, rgba(0, 0, 0, 0));
  padding: 1rem;
  z-index: 1;
}
.blog-img .text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #1d1d1d00;
  padding: 1rem;
  z-index: 2;
}
.blog-img .text a {
  color: white;
}

.blog-img:hover .text {
  background-color: #444444a4;
}
.blog-img .text a:hover {
  color: #ef4076;
}

/* blog details */

.blog-img-wrapper img {
  height: 400px;
  object-fit: cover;
}
.blog-info {
  background: linear-gradient(90deg, #5e2f90, #ef4076);
  color: #fff;
  padding: 0 10px;
}
.blog-info ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0;
  padding: 20px 0;
}
.blog-info ul li {
  padding-right: 40px;
  font-size: 20px;
  font-weight: 300;
}
.blog-info ul li i {
  margin-right: 10px;
}

@media (min-width: 1050px) {
  .costum-container {
    width: 1049px;
  }
}
@media (min-width: 1150px) {
  .costum-container {
    width: 1149px;
  }
}

.detail-wrapper .content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 25px;
  color: white;
  background-color: rgba(39, 39, 39, 0.644);
  display: flex;
  align-items: flex-end;
}

.cources-section .content-wrapper {
  border: 1px solid #d9d9d9;
}
.cources-section .content-wrapper:hover {
  box-shadow: 0 2px 8px #b2b2b2;
  color: inherit;
}

.cources-section .content-wrapper .text {
  position: relative;
  padding: 32px;
  padding-bottom: 100px;
}
.cources-section .content-wrapper .text1 {
  padding-bottom: 32px;
}

.cources-section .content-wrapper .text .more-less-btn {
  position: absolute;
  bottom: 30px;
}
.cources-section .content-wrapper .text span {
  display: inline-block;
  font-size: 14px;
}
.cources-section .content-wrapper .text h3 {
  font-weight: 700;
  padding: 10px 0;
}

.bg {
  background-color: white;
}
.bg2 {
  background-color: #f4f4f4;
}
.g-img{
  padding: 0 15px;
}
.g-img .img-wrapper{
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.397);
}
.gallery-slider-wrapper{
  margin-bottom: 70px;
}

.gallery-slider-wrapper .slick-dots{
  bottom: -30px !important;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  animation: fadeIn 0.3s;
}

.popup-img {
  max-width: 80%;
  max-height: 80%;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@media (max-width: 768px) {
  .slick-dots li{
    width: 10px;
    height: 10px;
  }
  .popup{
    display: none;
  }
  .g-img{
    padding: 0 5px;
  }
  .g-img .img-wrapper{
    box-shadow: 0 0 15px rgba(0, 0, 0, 0);
  }
  .gallery-slider-wrapper{
    margin-bottom: 40px;
  }
  .categories-wrapper h3{
    margin-bottom: 0px;
    font-size: 30px;
  }
  .ant-collapse-header-text {
    font-size: 22px;
    padding-right: 32px;
    font-weight: 400;
  }
  .ant-collapse-header .ant-collapse-expand-icon svg {
    font-size: 22px;
  }
  .banner1-wrapper .content-wrapper{
    min-height: auto;
  }
}
