@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 1rem;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
}
h2.heading {
  font-size: 32px;
  font-weight: 500;
}
.fw-3 {
  font-weight: 300 !important;
}
.fw-4 {
  font-weight: 400 !important;
}
.fw-5 {
  font-weight: 500 !important;
}
.fw-6 {
  font-weight: 600 !important;
}
.fw-7 {
  font-weight: 700 !important;
}
.fw-8 {
  font-weight: 800 !important;
}
.fw-9 {
  font-weight: 900 !important;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
  line-height: 1.5;
  font-size: 16px;
}

a {
  color: #e3e4ff;
  text-decoration: none;
}
.effect {
  position: relative;
  padding-bottom: 5px;
}
.effect::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #ffa65d;
  transition: 0.5s ease-in-out;
}
.effect:hover::after {
  width: 100%;
}
.effect-white::after {
  background-color: #ffffff;
}

a:hover {
  text-decoration: none;
}
video {
  display: block;
}

.h2,
h2 {
  font-size: 2rem;
}

img {
  width: 100%;
  display: block;
}

.page-content {
  width: 100%;
  margin-top: 85px;
}

.text-justify {
  text-align: justify;
}

.section-heading {
  font-size: 32px;
  font-weight: 400;
  line-height: 1.2;
}
.bg-black {
  background-color: #000;
}
.bg-grey {
  background-color: #e7e7e7;
}
.text-white {
  color: white !important;
}
ul {
  padding-left: 0;
}
/* button for all pages start ----------------------------- */
.btn {
  background: #000020;
  margin-top: 30px;
  color: white;
  padding: 10px 50px;
  border-radius: 25px;
  margin-top: 15px;
  display: inline-block;
  border: none;
  /* position: relative;
  overflow: hidden; */
}
.btn:hover {
  background: #c34448;
  color: rgb(255, 255, 255) !important;
}

.btn-white {
  background: rgba(0, 0, 0, 0.205);
  border: 2px solid white;
}
.bg-even {
  background-color: #f5f5f5;
}

.btn-transperent {
  color: #000;
  padding: 7px 30px;
}

.flex-centet-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-centet-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-centet-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.point_list li {
  list-style: disc;
  margin-bottom: 12px;
}

.position-relative {
  position: relative;
}
.py-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.pb-50 {
  padding-bottom: 50px;
}

.w-100 {
  width: 100% !important;
}
.h-100 {
  height: 100% !important;
}
.container {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}

.mb-0 {
  margin-bottom: 0 !important;
}

.form-btn {
  border: none;
}


