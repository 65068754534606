:root {
  --color1: #392f5c;
  --color2: #342c5b;
  --color-gredient: #c44243;
 
}

.img {
  width: 100%;
  position: relative;
}

.heading span{
  padding: 5px 15px;
  color: white;
  border-radius: 15px;
  background: linear-gradient(90deg, #342c5b, #c44243);
}
.heading h2{
  font-size: 50px;
  font-weight: 700;
  color: var(--color1);
  text-transform: uppercase;
}

/* home banner start */


.home-banner-wrapper .banner-item .img{
  width: 100%;
  
}
.home-banner-wrapper .banner-item  img{
  width: 100%;
 min-height: 520px;
  object-fit: cover;
  display: inline-block;
}

.exp-wrapper{
  background-color: var(--color1);
  color: white;
}
.exp-wrapper h2{
  color: var(--color2);
  font-size: 50px;
  font-weight: 700;
}

.home-service-wrapper .text-wrapper h3,
.why-wrapper .item h3 {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(158deg, #342c5b, #c44243);
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 40px;
  padding: 20px 0;
}
.why-wrapper .slide-wrapper{
  padding: 25px;
}
.why-wrapper .slide-wrapper .item {
 
  border-radius: 35px;
  overflow: hidden;
  box-shadow: 10px 10px 15px #16161625, inset 10px 10px 15px #2c2c2c23;
}
.why-wrapper .slick-dots{
  bottom: -20px;
}
.home-service-wrapper .wrapper  {
  padding: 30px;
  background: rgb(8 8 8);
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 10px 10px 15px #bdbdbd1c, inset 10px 10px 15px #42414114;
}

/* home categories start */
.categories-wrapper .wrapper{
position: relative;


}
.categories-wrapper .wrapper:hover .name{
  display: none;
}
.categories-wrapper .name{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 7px);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
}
.categories-wrapper .wrapper .img{
  height: 300px;
}
.categories-wrapper .wrapper img{
  height: 100%;
  object-fit: cover;
  display: block;
}

.our-category{
  display: flex;
  gap: 20px;
}
.our-category .img-wrapper img{
  flex-grow: 1;
  border-radius: 50%;
  overflow: hidden;
  transition:.5s ease-in-out ;
}
.our-category .img-wrapper:hover img{
border-radius: 20px;
}
/* brands start */
.brands-wrapper .img-wrapper{
  width: 70%;
  margin: 15px auto;
}
.brands-wrapper .img-wrapper img{
  opacity: .4;
  transition: .5s ease-in-out;
}
.brands-wrapper .img-wrapper:hover img{
  opacity: 1;
}


/* products start */

.Products-wrapper .wrapper ,.service-wrapper1 .wrapper,.service-wrapper .wrapper {
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.356),inset 10px 10px 15px rgba(0, 0, 0, 0.144);
}

.service-wrapper1 .wrapper .text-wrapper{
 
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 20px 20px 0px 20px;
  background: linear-gradient(1deg, #000000b3, transparent);
}
.service-wrapper1 .wrapper .text-wrapper p{
  font-size: 20px ;
  color: white;
}
.service-wrapper .wrapper .text-wrapper{
  padding: 20px;
}
.service-wrapper a {
  color: var(--color1);
  font-weight: 500;
}

.service-wrapper .wrapper {
  height: 100%;
}
.categories-wrapper a{
  color: white;
}
 .offer-wrapper .img-wrapper{
  z-index: 2;
 }
 .offer-wrapper .text-container{
  position: relative;
  z-index: 3;
  background: linear-gradient(90deg, #000000d6, transparent);
 }
 .offer-wrapper .text-container .wrapper{
  min-height: 400px;
  padding: 20px 0;
  display: flex ;
align-items: center;
justify-content: center;
color: white;
 }
 
 .testimonial-wrapper .wrapper{
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.192),inset 5px 5px 15px rgba(0, 0, 0, 0.144);
}


 .testimonial-wrapper .item-wrapper{
 
 margin: 30px 20px;
 }

.testimonial-wrapper .img{
  width: 100px;
}

.testimonial .slick-dots{
bottom: -20px !important;
}




@media (max-width: 992px) {
  header .content{
    padding: 0;
  }
  .page-content {
    width: 100%;
    margin-top: 70px;
}
  .home-banner-content .home-banner-title {
    font-size: 40px;
    line-height: 50px;
  }
  .home-banner-content .home-content p {
    font-size: 1.3rem;
    line-height: 32px;
  }
 
}

@media (max-width: 768px) {
  .none-md{
    display: none;
  }
  .home-banner-wrapper .banner-item  img{
   min-height: 300px;
    
  }

  .heading h2{
    font-size: 30px;
    font-weight: 700;
    color: var(--color1);
    text-transform: uppercase;
  }


  .gallery-wrapper {
    margin-top: 20px;
    padding-bottom: 10px;
  }
  .gallery-banner {
    background-position: center;
    background-size: cover;
    height: auto;
  }
  .service-wrapper {
    padding: 20px 15px;
  }
  .punchline-left,
  .punchline-right {
    height: 100% !important;
    padding: 20px 10px;
  }
  .punchline-left {
    border-right: 1px solid rgba(255, 255, 255, 0);
  }
}
@media (max-width: 550px) {
  .differentiator-item-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: 0.5s ease-in-out;
  }

  .slick-center .banner-item .differentiator-item-wrapper {
    width: 100%;
    height: 100%;
  }

  .home-banner-content {
    position: absolute;
    top: 0px;
    display: block;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 30px;
    background: linear-gradient(176deg, #000000a6, #00000042);
  }
  .home-banner-content .home-content {
    width: 95%;
    margin: 0 auto;
  }
  .home-banner-content .home-content p {
    margin-bottom: 0.5rem;
  }

  .home-banner-content .home-content .hide-content {
    display: block;
    opacity: 1;
  }
}
