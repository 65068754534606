header {
  width: 100%;
  top: 0;
  z-index: 1000;
  background: rgb(255, 255, 255);
  position: fixed;
  padding: 3px 0;
}

header a:hover {
  color: #d41a27;
}

/* nav part start ----------------------------- */

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}
.logo {
  width: 100px;
  padding: 5px 0;
  margin-right: 20px;
}

.nav-right ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.nav-right ul li {
  padding: 25px 15px;
  transition: 1s;
}
.nav-right ul li a {
  font-size: 17px;
  font-weight: 500;
  color: rgb(44 35 76);
  padding-bottom: 5px;
  position: relative;
}
.nav-right ul li a::after{
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: linear-gradient(90deg, #342c5b, #c44243);
  transition: .5s ease-in-out;
}
.nav-right ul li a:hover::after{
  width: 100%;
}
.main-ul li{
  position: relative;
}
.drop {
 
  position: absolute;
  border-bottom: none;
  width: 200px;
  top: 100%;
  left: 0;
  background: #ffffff;
  overflow: hidden;
  z-index: -1;
  height: 0;
  transition: all 0.3s ease-out;

}
.drop ul{
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.drop ul li{
  padding: 8px 10px;
  
}
.bar {
  display: none;
}

.bar svg {
  font-size: 20px;
  margin: 0 20px;
  color: rgb(0, 0, 0);
}
@media (min-width: 993px) {
  .main-ul li:hover > .drop {
    z-index: 100;
    min-height: 200px;
    height: auto;
  }

  .main-page-link-wrapper li:hover > .sub-drop-item {
    display: block;
  }
}

@media (max-width: 992px) {
  .logo {
    width: 80px;
  }

  nav {
    padding: 0 20px;
  }

  .menu {
    width: 350px;
    position: absolute;
    top: 68px;
    left: -130%;
    margin-left: 0 !important;
    transition: 0.5s ease-in-out;
    background: rgb(255, 255, 255);
    max-height: 100vh;
    overflow: hidden;
    overflow-y: scroll;
    z-index: 10000;
  }

  .menu.active {
    left: 0;
  }
  .drop {
    position: relative;
    border-bottom: none;
    width: auto;
    top: 100%;
    left: 0;
    background: #ffffff;
    overflow: hidden;
    z-index: -1;
    height: auto;
    transition: all 0.3s ease-out;
    display: none;
}
.drop.open{
  display: block;
}
.drop ul {
  padding: 10px 10px !important;
  flex-direction: column;
  align-items: flex-start;
  justify-content: right;
}
  .nav-right ul {
    padding: 10px 30px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: right;
  }
  .nav-right ul li {
    padding: 10px 10px;
    transition: 1s;
  }
  .nav-right ul li a {
    display: block;
  }

  .bar {
    display: inline-block;
  }
}

@media (max-width: 500px) {
  .menu {
    width: 100%;
  }
}
footer{
  background: #000004;
}

.footer-wrapper .footer-logo{
width: 100px;

}
.footer-wrapper ul li{
  padding: 5px 0;
}

.footer-wrapper svg ,.footer-wrapper p ,.footer-wrapper a{
  color: white;
} 
.footer-wrapper .socal a{
  font-size: 22px; 
  margin: 5px;
}

.copyright {
  color: white;
  padding: 5px 0;
  background-color: #272727;
  padding: 10px 50px;
}

@media (max-width: 550px) {
  .footer-container {
    padding: 15px 15px;
  }
}
